import React, { useState } from 'react';
import { Grid, Typography } from '@mui/material';
import NavBar from 'components/navigation';
import { useLocation } from 'react-router-dom';
// import TradeInHeader from 'components/header/trade';
import TradeInHeader from '../../components/header/trade';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ContactForm from '../../components/forms/contact';
import { isPhoneValid } from 'utils/validation';
import { useNavigate } from 'react-router-dom';
const Contact = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    // type,
    // model,
    specification,
    selectedCondition,
    freeQuoteId
  } = location.state || {};

  const nationalities = [
    'Saudi Arabia',
    'United Arab Emirates',
    'Qatar',
    'Kuwait',
    'Oman',
    'Bahrain',
    'Lebanon',
    'Jordan',
    'Egypt',
    'Iraq',
    'Syria',
    'Israel',
    'Iran',
    'Yemen',
    'United States',
    'China',
    'Russia',
    'Germany',
    'France',
    'United Kingdom',
    'India',
    'Brazil',
    'Japan',
    'South Africa'
  ];

  const sortedCountries = nationalities.sort();

  const [shouldShowError, setShouldShowError] = useState(0);

  const formik = useFormik({
    initialValues: {
      name: '',
      phone: '',
      nationality: '',
      email: ''
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Please enter your name'),
      email: Yup.string()
        .email('Invalid Email address')
        .required('Please enter your email'),
      phone: Yup.string()
        .required('Phone number is required')
        .test('is-valid-phone', 'Phone is not valid', value => {
          return isPhoneValid(value);
        }),
      nationality: Yup.string()
        .required('Nationality is required')
        .test('is-valid-phone', 'Nationality is not valid', value => {
          return sortedCountries.includes(value);
        })
    }),
    onSubmit: values => {
      // Handle form submission (e.g., send data to the server)
      navigate('/collection', {
        state: { specification, selectedCondition, freeQuoteId, user: values }
      });
    }
  });

  return (
    <>
      <TradeInHeader />
      <NavBar />
      <div className="flex justify-center w-[100%] !mt-10">
        <form onSubmit={formik.handleSubmit}>
          <Grid
            container
            rowGap={2}
            className="flex md:!justify-center max-w-[300px] md:min-w-[500px] border-t-[2px] py-4  border-[primaryGrey]"
          >
            <Grid item xs={12}>
              {' '}
              <Typography className="!font-bold md:!text-[1.1rem]">
                Contact Details
              </Typography>
            </Grid>
            <ContactForm
              setShouldShowError={setShouldShowError}
              shouldShowError={shouldShowError}
              formik={formik}
              nationalities={sortedCountries}
            />
          </Grid>
        </form>
      </div>
    </>
  );
};
export default React.memo(Contact);
