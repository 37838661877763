import { useReducer } from 'react';

const SET_PROPERTY = 'SET_PROPERTY';
export const BARND_TYPE = {
  brandData: 'brandData',
  modelData: 'modelData',
  attributesData: 'attributesData',
  attributeMetaData: 'attributeMetaData',
  loading: 'loading'
};

function dataReducer(state: any, action: any) {
  switch (action.type) {
    case SET_PROPERTY:
      return { ...state, [action.key]: action.payload };
    default:
      return state;
  }
}

function useBrandAndModel() {
  const initialState = {
    brandData: [],
    modelData: [],
    attributesData: {},
    brandError: '',
    modelError: '',
    attributeMetaData: {},
    loading: false
  };

  const [state, dispatch] = useReducer(dataReducer, initialState);

  const setProperty = (key: any, payload: any) => {
    dispatch({ type: SET_PROPERTY, key, payload });
  };

  return { ...state, setProperty };
}

export default useBrandAndModel;
