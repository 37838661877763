import { AttributeTypes } from 'types/brandTypes';

export const mapAttributeData = (data: any) => {
  const MappedAttribute: AttributeTypes = {};
  if (data?.attributes) {
    MappedAttribute[data?.attributes?.key] = {
      isNextLevelAttributesAvailable: data?.isNextLevelAttributesAvailable,
      ...data?.attributes,
      values: data?.attributes?.values.map((data: string) => {
        return {
          isClicked: false,
          value: data
        };
      })
    };
  }
  return MappedAttribute;
};

export const formUpdatedAttribute = (
  attributesData: AttributeTypes,
  key: string,
  value: string
) => {
  let attributeKey: any = Object.keys(attributesData);
  const indexValue = attributeKey.indexOf(key);
  if (indexValue > -1) {
    var currentSelectedValue = null;
    var currentSelectedKey = null;
    var allSelectedAttribute: any = [];
    let updatedAttributes = Object.fromEntries(
      Object.entries(attributesData)
        .filter(([Key, _]) => {
          const attributeIndex = attributeKey.indexOf(Key);
          return attributeIndex <= indexValue;
        })
        .map(([attributeKey, attribute]: any) => {
          const attributeIndex = attributeKey.indexOf(attributeKey);

          if (attributeIndex <= indexValue) {
            const updatedValues = attribute.values.map((item: any) => {
              if (item.value === value) {
                currentSelectedValue = value;
                currentSelectedKey = key;
                allSelectedAttribute.push({
                  key: attributeKey,
                  value: value
                });
                return { ...item, isClicked: true };
              } else {
                if (item.isClicked) {
                  allSelectedAttribute.push({
                    key: attributeKey,
                    value: item.value
                  });
                }
                return item;
              }
            });

            return [attributeKey, { ...attribute, values: updatedValues }];
          } else {
            return [attributeKey, attribute];
          }
        })
    );
    return {
      updatedAttributes: updatedAttributes,
      currentSelectedValue,
      currentSelectedKey,
      allSelectedAttribute
    };
  } else
    return {
      updatedAttributes: attributesData,
      selectedValue: null,
      selectedKey: null
    };
};

export const attributeBodyMap = (currentSelectedValue: any) => {
  return {
    attributes: currentSelectedValue
  };
};

export const formCreateRequestData = ({
  selectedValue,
  selectedCondition,
  user,
  geoLocation
}: any) => {
  let formattedData = {
    user: {
      name: user.name,
      phoneNumber: user.phone,
      nationality: user.nationality,
      email: user.email
    },
    inventoryLocation: {
      address: selectedValue,
      latitude: geoLocation.latitude,
      longitude: geoLocation.longitude
    },
    conditionId: selectedCondition.conditionId
    // condition: {
    //   ...selectedCondition
    // }
  };
  return formattedData;
};

export const formEnquiryData = (formData: any) => {
  return {
    category: formData.categoryName,
    brand: formData.brandName,
    model: formData.modelName,
    additionalInfo: formData.listings,
    customerName: formData.userName,
    mobileNo: formData.phone,
    email: formData.userEmail
  };
};
