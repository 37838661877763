import React, { useEffect, useMemo } from 'react';
import TradeInHeader from 'components/header/trade';
import NavBar from 'components/navigation';
import BreadCrumbs from 'components/breadCrumbs';
import { useParams, useSearchParams } from 'react-router-dom';
import { Grid } from '@mui/system';
import PopularBrandsLayout from 'layout/brand/popularBrandLayout';
import AllBrandLayout from 'layout/brand/allBrandLayout';
import { checkIfAnyParamsExists, currentLayoutToDisplay } from 'utils/brands';
import ProductLayout from 'layout/product/productLayout';
import ProductSpecificLayout from 'layout/product/productSepecification';
import { useLocation } from 'react-router-dom';
import { getBrandPageDetails } from 'api/device/get';
import useBrandAndModel from 'customHooks';
import { BARND_TYPE } from 'customHooks/index';
import { mapAttributeData } from 'mappers';
import { useNavigate } from 'react-router-dom';
import {
  AttributeTypes,
  BrandCategoryTypes,
  BrandModelTypes
} from 'types/brandTypes';

// import Apitypes from 'api/constant';

const BrandCategory = () => {
  const {
    brandData,
    modelData,
    setProperty,
    attributesData,
    // brandError,
    // modelError,
    attributeMetaData,
    loading
  } = useBrandAndModel();
  const location = useLocation();
  const navigate = useNavigate();
  const { categoryId, brandId, modelId } = location.state || {};

  useEffect(() => {
    const checkIfPramasExists = checkIfAnyParamsExists({
      categoryId,
      brandId,
      modelId
    });
    if (checkIfPramasExists) {
      navigate('/');
    }
  }, [categoryId, brandId, modelId]);

  const fetchBrandCategory = React.useCallback(async () => {
    try {
      setProperty(BARND_TYPE.loading, true);
      const { data, type } = await getBrandPageDetails({
        categoryId,
        brandId,
        modelId
      });
      if (type !== BARND_TYPE.attributesData) {
        setProperty(type, data);
        setProperty(BARND_TYPE.loading, false);
      } else {
        const attributeData = mapAttributeData(data);
        setProperty(type, attributeData);
        setProperty(BARND_TYPE.loading, false);
      }
    } catch (error) {
      setProperty(BARND_TYPE.loading, false);
      console.error('Error fetching device category:', error);
    }
  }, [categoryId, brandId, modelId]);

  useEffect(() => {
    fetchBrandCategory();
  }, [fetchBrandCategory]);

  const selectedQuery = useParams();
  const [searchParams] = useSearchParams();
  const productParams = searchParams.get('product');
  let breadCrumbs = useMemo(() => {
    let queryArry = Object.values(selectedQuery);

    if (!productParams) return queryArry;
    else return [...queryArry!, productParams];
  }, [selectedQuery, productParams]);

  let brandLayout = (brandData: BrandCategoryTypes[], loading: Boolean) => {
    return (
      <Grid
        container
        columnGap={2}
        rowGap={4}
        className="flex lg:justify-center mt-12 mb-16"
      >
        <PopularBrandsLayout categoryId={categoryId}></PopularBrandsLayout>
        <AllBrandLayout
          brandData={brandData}
          categoryId={categoryId}
          loading={loading}
        ></AllBrandLayout>
      </Grid>
    );
  };
  let productLayout = (modelData: BrandModelTypes[]) => {
    return (
      <Grid
        container
        columnGap={2}
        rowGap={4}
        className="flex lg:justify-center mt-16 mb-16"
      >
        <ProductLayout modelData={modelData} loading={loading} />
      </Grid>
    );
  };
  let productSpecificationLay = (
    attributesData: AttributeTypes,
    attributeMetaData: any,
    loading: Boolean
  ) => {
    return (
      <>
        <Grid
          container
          columnGap={200}
          rowGap={4}
          className="flex lg:justify-center pl-2 md:pl-0 md:!items-start !min-h-[170px] !items-end md:mt-6 mt-2 mb-1 md:mb-16"
        >
          <ProductSpecificLayout
            attributesData={attributesData}
            attributeMetaData={attributeMetaData}
            modelId={modelId}
            setProperty={setProperty}
            loading={loading}
          />
        </Grid>
      </>
    );
  };

  const DisplayLayout = useMemo(() => {
    let totalLayout = [
      () => brandLayout(brandData, loading),
      () => productLayout(modelData),
      () => productSpecificationLay(attributesData, attributeMetaData, loading)
    ];
    const Layout = currentLayoutToDisplay(totalLayout, breadCrumbs);
    if (Layout) return Layout;
    else return <></>;
  }, [
    breadCrumbs,
    brandData,
    modelData,
    attributesData,
    attributeMetaData,
    loading
  ]);

  return (
    <>
      <TradeInHeader />
      <NavBar />
      <BreadCrumbs breadCrumbs={breadCrumbs} />
      {/* <Grid
        container
        columnGap={2}
        rowGap={4}
        className="flex lg:justify-center mt-16 mb-16"
      > */}
      <DisplayLayout />
      {/* </Grid> */}
    </>
  );
};
export default React.memo(BrandCategory);
