import {
  Box,
  Card,
  CardActions,
  CardContent,
  Grid,
  IconButton,
  InputAdornment,
  Typography
} from '@mui/material';
import SearchInput from '../../components/input';
import { allBrandInputStyle } from 'constants/styles';
import { useMemo, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import slugify from 'slugify';
import { BrandModelTypes } from 'types/brandTypes';
import { formImageUrl } from 'utils/brands';
import NotInListing from 'components/popup/listing';
import CardLoader from 'layout/loader/card';
import { CardLayoutArrayConst } from '../../constants/index';

interface IProductLayoutProps {
  modelData: BrandModelTypes[];
  loading: Boolean;
}

let sx = allBrandInputStyle;

const ProductLayout: React.FC<IProductLayoutProps> = ({
  modelData,
  loading
}) => {
  let navigate = useNavigate();
  const location = useLocation();
  let [open, setOpen] = useState(false);

  const handleProductGridClick = (clickedGridData: BrandModelTypes) => {
    const currentPath = location.pathname;
    const slug = slugify(clickedGridData.name, { lower: true });
    navigate(`${currentPath}/${slug}`, {
      state: {
        overallData: clickedGridData,
        modelId: clickedGridData._id
      }
    });
  };

  const [serachedProduct, setSearchProuct] = useState('');
  const allProducts = useMemo(() => {
    if (!loading) {
      if (serachedProduct?.length === 0) return modelData;
      else {
        let filteredProducts = modelData.filter(data => {
          return data?.name
            ?.toLowerCase()
            .includes(serachedProduct?.toLowerCase());
        });
        return filteredProducts || [];
      }
    } else return CardLayoutArrayConst;
  }, [serachedProduct, CardLayoutArrayConst]);

  const handleSearchedProducts = (e: { target: { value: string } }) => {
    setSearchProuct(e.target.value);
  };

  const CheckNotInListing = () => {
    setOpen(true);
  };

  return (
    <>
      <Grid xs={12} className={' !px-4 w-[100%] lg:w-[auto]'}>
        <NotInListing open={open} setOpen={setOpen} />
        <Box className="xl:w-[1100px] lg:w-[880px] min-w-[auto] ">
          <Box className="flex justify-between mb-2">
            <Typography className="md:!text-[1.1rem] !text-[1rem] !font-[600]">
              Models
            </Typography>
            <Typography
              onClick={() => CheckNotInListing()}
              className="md:!text-[1.1rem] !text-[0.9rem] !font-[600] !text-primaryOrange cursor-pointer"
            >
              Not in the listings?
            </Typography>
          </Box>
          <SearchInput
            onChange={(e: any) => {
              handleSearchedProducts(e);
            }}
            className="  sm:!min-w-[100%] !w-[100%] !rounded-10px bg-white"
            sx={sx}
            placeholder="Search"
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end">
                    <SearchIcon style={{ fontSize: 30 }} />
                  </IconButton>
                </InputAdornment>
              )
            }}
          ></SearchInput>
        </Box>
        <Box>
          <Grid
            container
            columnGap={0}
            className="cursor-pointer xl:!w-[1100px] lg:!w-[880px] min-w-[auto]"
          >
            {allProducts?.map((data: any) => {
              return (
                <Grid
                  key={data.name}
                  lg={2}
                  sm={3}
                  md={2}
                  xs={4}
                  className="mt-6"
                >
                  <Card
                    sx={{ boxShadow: 'none', border: '3px solid white' }} // Removes the default shadow
                    onClick={() => handleProductGridClick(data)}
                    className="!rounded-[16px]  !p-[0px]  h-[100%] !bg-[#fff]  !max-w-[90%]    md:!min-w-[142px] lg:!min-w-[120px] xl:!min-w-[162px] "
                  >
                    {loading ? (
                      <CardLoader type={'card'} />
                    ) : (
                      <>
                        <CardContent className=" !py-[0px] px-4">
                          <img
                            alt={'Image'}
                            className="md:!w-[140px] lg:!h-[120px] !h-[90px] !w-[100%] !h-[80px] object-contain"
                            src={formImageUrl(data?.image)}
                          ></img>
                        </CardContent>
                        <CardActions className="flex justify-center items-center !pt-0 !text-center">
                          <Typography className="!font-[500] !text-primaryBlack !text-[0.65rem] md:!text-[1.1rem]  !mb-2">
                            {data.name}
                          </Typography>
                        </CardActions>
                      </>
                    )}
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Grid>
    </>
  );
};
export default ProductLayout;
