export let statusCodes = {
    
    success: 200,                // OK: The request has succeeded
    created: 201,                // Created: The request has been fulfilled and a new resource is created
    accepted: 202,               // Accepted: The request has been accepted for processing, but the processing is not complete
    noContent: 204,              // No Content: The server successfully processed the request, but is not returning any content
  
    // Client Error Codes
    badRequest: 400,             // Bad Request: The server cannot or will not process the request due to client error
    unauthorized: 401,           // Unauthorized: Authentication is required and has failed or has not yet been provided
    forbidden: 403,              // Forbidden: The server understands the request but refuses to authorize it
    notFound: 404,               // Not Found: The requested resource could not be found
  
    // Server Error Codes
    internalServerError: 500,    // Internal Server Error: The server encountered an unexpected condition
    notImplemented: 501,         // Not Implemented: The server does not support the functionality required to fulfill the request
    badGateway: 502,             // Bad Gateway: The server received an invalid response from the upstream server
    serviceUnavailable: 503,     // Service Unavailable: The server is currently unavailable (due to overload or maintenance)
    gatewayTimeout: 504          // Gateway Timeout: The server did not receive a timely response from the upstream server
  };
  