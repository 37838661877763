import { Box, Grid, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { AttributeTypes } from 'types/brandTypes';
import { BARND_TYPE } from 'customHooks/index';
import {
  attributeBodyMap,
  formUpdatedAttribute,
  mapAttributeData
} from 'mappers';
import React, { useState } from 'react';
import { generateFreeQuote, getBrandPageDetails } from 'api/device/get';
import OverlayLoader from 'components/loader/loader';
// import NotInListing from 'components/popup/listing';
import NotInListing from 'components/popup/listing';
interface IProductSpecific {
  attributesData: AttributeTypes;
  modelId: string;
  setProperty: any;
  attributeMetaData: any;
  loading: Boolean;
}
const ProductSpecificLayout: React.FC<IProductSpecific> = ({
  attributesData,
  modelId,
  setProperty,
  attributeMetaData,
  loading
}) => {
  let navigate = useNavigate();
  const location = useLocation();
  let [open, setOpen] = useState(false);

  const handleSpecificationGridClick = async (key: string, value: any) => {
    //Check if same value is clicked again to prevent un-necessary api calls
    const isCurrentAttributeClicked =
      attributeMetaData?.allSelectedAttribute?.find(
        (obj: any) => obj.value === value
      );
    if (!isCurrentAttributeClicked) {
      //Get the updated Attribute based on user interaction on att value grid
      let revertedAttribute = attributesData;
      let revertedAttributeMetaData = attributeMetaData;
      const {
        updatedAttributes,
        currentSelectedKey,
        currentSelectedValue,
        allSelectedAttribute
      } = formUpdatedAttribute(attributesData, key, value);

      //Set attribute metaData based on the the current selected value and key
      currentSelectedKey &&
        currentSelectedValue &&
        setProperty(BARND_TYPE.attributeMetaData, {
          currentSelectedAttKey: currentSelectedKey,
          currentSelectedAttValue: currentSelectedValue,
          allSelectedAttribute: allSelectedAttribute
        });

      //If no issue with the formed updated attribute then save the value else show error
      if (updatedAttributes[key]) {
        // setProperty(BARND_TYPE.attributesData, updatedAttributes);
        if (updatedAttributes[key]?.isNextLevelAttributesAvailable) {
          const mappedBody = attributeBodyMap(allSelectedAttribute);
          const { data } = await getBrandPageDetails({
            modelId,
            body: mappedBody
          });
          const convertedAttData = mapAttributeData(data);
          setProperty(BARND_TYPE.attributesData, {
            ...updatedAttributes,
            ...convertedAttData
          });
          if (!data.isNextLevelAttributesAvailable) {
            const { matchingDevices } = data;

            if (matchingDevices?.length > 0) {
              let [{ _id }] = matchingDevices;
              setProperty(BARND_TYPE.loading, true);

              const { data } = await generateFreeQuote(_id);
              //If issue with generating quote
              if (data.length !== 0) {
                setProperty(BARND_TYPE.loading, false);
                const currentPath = location.pathname;
                navigate(`${currentPath}/condition`, { state: data });
              } else {
                //If issue with generating quote revert previous selected attribute
                setProperty(
                  BARND_TYPE.attributeMetaData,
                  revertedAttributeMetaData
                );
                setProperty(BARND_TYPE.attributesData, revertedAttribute);
                setProperty(BARND_TYPE.loading, false);
              }
            }
          }
        }
      } else {
        setProperty(BARND_TYPE.attributesData, revertedAttribute);
        console.error('Error while updating attribute');
      }
      return;
    } else {
      //If same grid is selected multiple time
      return;
    }
  };
  const CheckNotInListing = () => {
    setOpen(true);
  };

  return (
    <>
      <NotInListing open={open} setOpen={setOpen} />
      {Object.keys(attributesData).map((key, index) => {
        const attribute = attributesData[key];
        return (
          <Grid md={12} className={' !px-2 lg:!px-0 w-[100%] lg:w-[auto]'}>
            <OverlayLoader loading={loading} />

            <Box className=" lg:w-[700px] min-w-[auto] ">
              <Box className="flex justify-between mb-2">
                <Typography className="md:!text-[1.1rem] !tracking-wide !text-[1rem] !font-[600]">
                  {attribute.key}
                </Typography>
                {index === 0 && (
                  <Typography
                    onClick={() => CheckNotInListing()}
                    className="md:!text-[1.1rem] !text-[0.9rem] relative md:right-[10px] !font-[600] !text-primaryOrange cursor-pointer"
                  >
                    Not in the listings?
                  </Typography>
                )}
              </Box>
            </Box>
            <Box>
              <Grid container rowGap={1} columnGap={2}>
                {attribute.values.map(data => {
                  return (
                    <Grid
                      key={data.value}
                      lg={2}
                      sm={3}
                      md={2}
                      xs={3}
                      className={`mt-2`}
                    >
                      <Box
                        onClick={() =>
                          handleSpecificationGridClick(key, data?.value)
                        }
                        className={` ${data.isClicked ? 'border-2 border-primaryBlue' : ''} cursor-pointer flex !justify-center md:min-w-[auto] md:w-[100px]   !items-center rounded-[15px] md:h-[60px] h-[50px] bg-white`}
                      >
                        <Typography className="text-center !text-[0.8rem] md:!text-[1.1rem]">
                          {data?.value!}
                        </Typography>
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          </Grid>
        );
      })}
    </>
  );
};
export default ProductSpecificLayout;
