import React, { useCallback, useRef, useState } from 'react';
import Carousel from 'components/carousel';
import { Box } from '@mui/material';
import TradeInHeader from 'components/header/trade';
import NavBar from 'components/navigation';
import DeviceSelectionLayout from 'layout/device/deviceSelectionLay';
import CustomSlider from 'components/slider';
import { Navigation, Autoplay } from 'swiper/modules';

const DeviceSelection = () => {
  const images = [
    'https://via.placeholder.com/900x400',
    'https://via.placeholder.com/900x400/ff0000',
    'https://via.placeholder.com/900x400/00ff00',
    'https://via.placeholder.com/900x400/0000ff'
  ];

  const [currentSlide, setCurrentSlide] = useState(0);
  const swiperRef: any = useRef(null);

  const [slidesPerView, setSlidesPerView] = useState(1.2);

  const handleResize = () => {
    if (window.innerWidth >= 768) {
      setSlidesPerView(1.9);
    } else {
      setSlidesPerView(1.2);
    }
  };

  React.useEffect(() => {
    // Set initial value
    handleResize();

    // Add event listener
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const updateSlide = (value: number) => {
    if (swiperRef.current) {
      swiperRef.current.slideTo(value);
      setCurrentSlide(value);
    }
  };

  // Debounce the slider change handler to improve performance
  const handleSliderChange = useCallback((_: any, value: any) => {
    updateSlide(value);
  }, []);

  const handleSlideChange = useCallback(() => {
    if (swiperRef.current) {
      setCurrentSlide(swiperRef.current.realIndex); // Update index instantly
    }
  }, []);

  return (
    <>
      <div className="md:mx-auto xl:max-w-6xl lg:max-w-3xl md:px-4 mb-20">
        <TradeInHeader />
        <NavBar />
        <>
          <Box className="!w-[100%] selection-carousel !my-2 md:!my-10">
            <Carousel
              onSwiper={(swiper: any) => (swiperRef.current = swiper)}
              onSlideChange={handleSlideChange}
              modules={[Navigation, Autoplay]}
              spaceBetween={20}
              slidesPerView={slidesPerView}
              centeredSlides={true}
              loop={true}
              isNavigation={true}
              pagination={{ clickable: true }}
              className="mySwiper"
              images={images}
              autoplay={{
                // Enable autoplay
                delay: 3000, // Delay between transitions in milliseconds
                disableOnInteraction: false // Continue autoplay after user interactions
              }}
            />
            <CustomSlider
              value={currentSlide}
              min={0}
              max={images.length - 1}
              step={1}
              onChange={handleSliderChange} // Directly update slide
              valueLabelDisplay="auto"
              marks={images.map((_, index) => ({ value: index }))}
            />
          </Box>
          <Box className="md:mt-2">
            <DeviceSelectionLayout />
          </Box>
        </>
      </div>
    </>
  );
};

export default React.memo(DeviceSelection);
