// import Breadcrumbs from '@mui/material/Breadcrumbs';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { Box, Typography } from '@mui/material';
import Button from 'components/button';
import { unslugify } from 'utils/routes';
import { useNavigate } from 'react-router-dom';

interface IbreadCrumbs {
  breadCrumbs?: any[];
}

const BreadCrumbs: React.FC<IbreadCrumbs> = ({ breadCrumbs }) => {
  const navigate = useNavigate();

  function handleClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    event.preventDefault();
  }

  const handleClickBack = (index: number) => {
    if (caseConvertedBreadCrumbs?.length !== index + 1 && index) {
      let navigateIndex = index;
      navigate(-navigateIndex);
    } else if (index === 0) {
      navigate(-1);
    }
  };

  // Get the deviceType param from the URL
  let caseConvertedBreadCrumbs: IbreadCrumbs['breadCrumbs'] =
    unslugify(breadCrumbs);
  return (
    <Box
      role="presentation"
      onClick={handleClick}
      className="flex md:justify-center "
    >
      <div className="flex md:w-[700px] w-[100%] border-t-[1px solid grey] ml-2 mr-3">
        <div className="flex justify-center items-center">
          <Button
            onClick={() => handleClickBack(0)}
            className="bg-primaryBlue p-1 w-10 h-10   !rounded-[10px]"
            content={
              <KeyboardArrowLeftIcon
                fontSize="medium"
                className="text-white "
                sx={{ fontSize: 30 }}
              />
            }
          />
        </div>
        <div
          className="border-t-[1px] border-b-[1px] w-[100%] pt-5 pb-2 px-5"
          style={{ borderColor: '#d6d7db' }}
        >
          <div>
            <Typography className="!text-primaryGrey  xl:!text-[1rem] !text-[1rem] ">
              Category{' '}
            </Typography>
          </div>
          <div className="flex">
            {caseConvertedBreadCrumbs?.map((data, index) => {
              return (
                <div
                  className="flex"
                  key={index}
                  onClick={() => handleClickBack(index)}
                >
                  <Typography className="!text-black  xl:!text-[0.95rem] !text-[0.9rem] ">
                    {data}
                  </Typography>
                  <Typography className="  !text-[0.95rem]  !text-primaryGrey !mx-2">
                    {'>>'}
                  </Typography>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Box>
  );
};
export default BreadCrumbs;
