import Header from 'components/header';
import Collection from 'pages/collection';
import Contact from 'pages/contact';
import DeviceCondition from 'pages/devices/brands/condtion';
import DeviceSelections from 'pages/devices/deviceSelections';
import RequestCreation from 'pages/requestCreation';
import BrandCategory from 'pages/devices/brands';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import ErrorBoundary from 'components/errorBoundary';
import NotFound from 'pages/pageNotFound';

function App() {
  const location = useLocation();

  return (
    <>
      {/* Render Header only if the route is not "/create-request" */}
      {location.pathname !== '/create-request' && <Header />}
      <Routes>
        {/* Device selection */}
        <Route
          path="/"
          element={
            <>
              <ErrorBoundary>
                <ToastContainer />
                <DeviceSelections />
              </ErrorBoundary>
            </>
          }
        />
        {/* Device Condition Route */}
        <Route
          path="/device/:type/:model/:specification/condition"
          element={
            <>
              <ErrorBoundary>
                <ToastContainer className="condition-toast-wrapper" />
                <DeviceCondition />
              </ErrorBoundary>
            </>
          }
        />
        <Route
          path="/device/:type/:model/:specification"
          element={
            <>
              <ErrorBoundary>
                <ToastContainer />
                <BrandCategory />
              </ErrorBoundary>
            </>
          }
        />
        {/* Collection Route */}
        <Route
          path="/collection"
          element={
            <>
              <ErrorBoundary>
                <ToastContainer />
                <Collection />
              </ErrorBoundary>
            </>
          }
        />
        <Route
          path="/contact"
          element={
            <ErrorBoundary>
              <Contact />{' '}
            </ErrorBoundary>
          }
        />
        <Route
          path="/device/:type"
          element={
            <>
              <ErrorBoundary>
                <ToastContainer />
                <BrandCategory />
              </ErrorBoundary>
            </>
          }
        />
        <Route
          path="/device/:type/:model"
          element={
            <>
              <ErrorBoundary>
                <ToastContainer />
                <BrandCategory />
              </ErrorBoundary>
            </>
          }
        />
        {/* Create Request Route (without Header) */}
        <Route
          path="/create-request"
          element={
            <>
              <ErrorBoundary>
                <ToastContainer />
                <RequestCreation />
              </ErrorBoundary>
            </>
          }
        />
        <Route path="*" element={<NotFound />} />{' '}
        {/* Catch all route for 404 */}
      </Routes>
    </>
  );
}

export default function Root() {
  return (
    <Router>
      <App />
    </Router>
  );
}
