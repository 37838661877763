import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Toast = ({ type, message, ...restProps }) => {
  const success = () => {
    return toast('Please select one condition', {
      autoClose: 3000,
      ...restProps
    });
  };

  const locationError = () => {
    return toast.error('Error while finding location please try again', {
      autoClose: 3000
    });
  };

  switch (type) {
    case 'success':
      return success;
    case 'locationError':
      return locationError;
    default:
      return success;
  }
};
export default Toast;
