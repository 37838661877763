import { Autocomplete, TextField } from '@mui/material';
import React from 'react';

interface IAutoComplete {
  nationalities: string[]; // Assuming this is an array of strings
  formik: any;
  sx: any;
}

const AutoComplete: React.FC<IAutoComplete> = ({
  sx,
  nationalities,
  formik
}) => {
  return (
    <Autocomplete
      freeSolo
      options={nationalities}
      sx={sx}
      id="nationality"
      value={formik.values.nationality}
      onChange={(_, newValue: string | null) => {
        formik.setFieldValue('nationality', newValue || '');
      }}
      filterOptions={(options, { inputValue }) => {
        // Check if inputValue is defined and filter accordingly
        const filteredOptions = options.filter(option =>
          option.toLowerCase().includes(inputValue.toLowerCase())
        );

        // Debugging: Log the filtered options

        // Return the filtered options
        return filteredOptions;
      }}
      renderInput={(params: any) => (
        <TextField
          {...params}
          placeholder="Nationality"
          variant="outlined"
          error={
            formik.touched.nationality && Boolean(formik.errors.nationality)
          }
          helperText={formik.touched.nationality && formik.errors.nationality}
          onBlur={formik.handleBlur}
        />
      )}
      renderOption={(props: any, option: any) => <li {...props}>{option}</li>}
    />
  );
};

export default AutoComplete;
