import React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';

const OverlayLoader = ({ loading }) => {
  return (
    loading && (
      <div className="fixed inset-0 bg-[rgba(4,42,103,0.2)] flex items-center justify-center z-50">
        <ClipLoader color="rgb(4, 42, 103)" size={50} />
      </div>
    )
  );
};

export default OverlayLoader;
