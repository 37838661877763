import { Box, Typography } from '@mui/material';
import React from 'react';

const TradeInHeader = () => {
  return (
    <Box className="text-center">
      <Typography className="text-primaryBlack !font-[600] !tracking-wide !text-[1.5rem] xl:!text-[1.6rem]">
        Trade-in your device
      </Typography>
    </Box>
  );
};

export default React.memo(TradeInHeader);
