import { Box, Typography } from '@mui/material';
import UnderLine from 'components/underline';
import React from 'react';
// import { NavBarType } from 'types/navBarTypes';
import { selectedNavPage } from 'utils/devices';
import { useLocation } from 'react-router-dom';

const NavBar: React.FC = () => {
  const location = useLocation();
  let pageName = React.useMemo(() => {
    const firstSegment = location.pathname.split('/')[1];
    return firstSegment;
  }, [location.pathname]);
  return (
    <>
      <Box className="flex justify-center items-center my-4">
        <div className="flex flex-col items-center">
          {/* Device Tab */}
          <Typography
            className={` !text-[0.9rem] md:!text-[1rem] cursor-pointer ${
              selectedNavPage(pageName, 'device') || !pageName
                ? '!text-primaryBlue !font-bold '
                : '!text-primaryGrey !font-medium'
            }`}
          >
            Device
          </Typography>

          <UnderLine
            selectType={pageName}
            isHomePage={!pageName}
            orgType={'device'}
          />
        </div>

        <Typography className="!font-bold !relative !bottom-[3px] !text-[1rem] !mx-1 md:!mx-3 text-primaryGrey">
          - - - - -
        </Typography>

        <div className="flex flex-col items-center">
          {/* Contact Tab */}
          <Typography
            className={` !text-[0.9rem] md:!text-[1em] cursor-pointer ${
              selectedNavPage(pageName, 'contact')
                ? '!text-primaryBlue !font-bold '
                : '!text-primaryGrey !font-medium'
            }`}
          >
            Contact
          </Typography>

          <UnderLine selectType={pageName} orgType={'contact'} />
        </div>

        <Typography className="!font-bold !relative !bottom-[3px] !text-[1rem] !mx-1 md:!mx-3 text-primaryGrey">
          - - - - -
        </Typography>

        <div className="flex flex-col items-center">
          {/* Collection Tab */}
          <Typography
            className={` !text-[0.9rem] md:!text-[1rem] cursor-pointer ${
              selectedNavPage(pageName, 'collection')
                ? '!text-primaryBlue !font-bold '
                : '!text-primaryGrey !font-medium'
            }`}
          >
            Collection
          </Typography>

          <UnderLine selectType={pageName} orgType={'collection'} />
        </div>
      </Box>
    </>
  );
};

export default NavBar;
