import { Slider } from '@mui/material';

interface ISliderPropTypes {
  value: any;
  min: number;
  max: number;
  step: number;
  onChange: any;
  valueLabelDisplay: 'auto' | 'on' | 'off' | undefined;
  marks: any;
}

const CustomSlider: React.FC<ISliderPropTypes> = ({ ...restProps }) => {
  return (
    <div className="flex justify-center" style={{ padding: '10px 0' }}>
      <div className="!w-[10%]">
        <Slider
          sx={{
            color: '#c9cdd7',
            height: '12px',
            borderRadius: '6px',
            '& .MuiSlider-thumb': {
              display: 'none' // Hide the thumb
            },
            '& .MuiSlider-mark': {
              display: 'none' // Hide the marks
            },
            '& .MuiSlider-track': {
              backgroundColor: 'rgb(4, 42, 103)'
            }
          }}
          {...restProps}
        />
      </div>
    </div>
  );
};
export default CustomSlider;
