import React, { useCallback } from 'react';
import TradeInHeader from 'components/header/trade';
import NavBar from 'components/navigation';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import Button from 'components/button';
// import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Toast from 'components/toast';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { AccordianStyles, AccordianSummary } from 'constants/styles';

// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const accordionData = [
  {
    title: 'Broken But Working',
    price: 'AED 350.00',
    content: ['Physical Damage But Turns ON', 'Screen/Panel Broken']
  },
  {
    title: 'Average',
    price: 'AED 350.00',
    content: ['Physical Damage But Turns ON', 'Screen/Panel Broken']
  },
  {
    title: 'Flawless',
    price: 'AED 350.00',
    content: ['Physical Damage But Turns ON', 'Screen/Panel Broken']
  },
  {
    title: 'As New',
    price: 'AED 350.00',
    content: ['Physical Damage But Turns ON', 'Screen/Panel Broken']
  }
];

const DeviceCondition = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { conditionWithPrices, freeQuoteId } = location.state || {};
  const [expanded, setExpanded] = React.useState<number | false>(false); // Holds the index of the expanded accordion
  const { type, model, specification } = useParams();
  let content = ['Physical Damage But Turns ON', 'Screen/Panel Broken'];
  const handleAccordionChange =
    (panelIndex: number) => (isExpanded: boolean) => {
      setExpanded(isExpanded ? panelIndex : false); // Expand the clicked accordion, collapse others
    };
  const handleAccordianOutlineClick = () => {
    setExpanded(false);
  };
  const onSubmitCondition = useCallback(() => {
    if (expanded === false) {
      Toast({
        type: 'success',
        message: 'Please select one condition',
        autoClose: 3000
      })();
    } else {
      let selectedCondition = conditionWithPrices[expanded];
      navigate('/contact', {
        state: {
          type: type,
          model: model,
          specification: specification,
          selectedCondition: selectedCondition,
          freeQuoteId
        }
      });
    }
  }, [
    expanded,
    type,
    model,
    specification,
    conditionWithPrices,
    accordionData,
    freeQuoteId
  ]);
  return (
    <>
      <TradeInHeader />
      <NavBar />
      <div className="md:!max-w-[600px]  md:!m-0 md:!mx-auto  !mx-[20px]">
        {/* <div className="!mt-10"> */}
        {conditionWithPrices?.map((item: any, index: any) => {
          const panelId = `panel-${index}`;
          const isExpanded = expanded === index;

          return (
            <Accordion
              onChange={() => handleAccordionChange(index)(true)}
              key={panelId}
              expanded={isExpanded}
              sx={AccordianStyles(conditionWithPrices, isExpanded, index)}
            >
              <AccordionSummary
                expandIcon={''}
                aria-controls={`${panelId}-content`}
                id={`${panelId}-header`}
                className="!flex !justify-between"
                sx={AccordianSummary}
              >
                <Typography className="!text-primaryBlue md:!text-[1rem] !text-[0.8rem] !font-bold">
                  {item.conditionName}
                </Typography>
                <Typography className="!text-primaryBlue md:!font-[600] !font-bold !text-[1rem] md:!text-[1.1rem]">
                  {item.price}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className="!pt-0 relative !bottom-[5px] cursor-pointer">
                <div
                  onClick={() => {
                    handleAccordianOutlineClick();
                  }}
                >
                  <Typography className="!text-primaryGrey md:!text-[0.8rem] !text-[0.7rem] !mb-1">
                    CONDITION
                  </Typography>
                  {content.map((contentData, idx) => (
                    <Typography
                      key={idx}
                      className="!text-black md:!text-[0.8rem] !text-[0.7rem] !font-[500]"
                    >
                      {contentData}
                    </Typography>
                  ))}
                </div>
              </AccordionDetails>
            </Accordion>
          );
        })}
        <Box>
          <Button
            className="!rounded-[10px] !bg-primaryBlue text-white w-[100%] md:my-6 my-5 p-2 md:!text-[1.1rem] !text-[0.8rem]"
            content={'Next'}
            onClick={onSubmitCondition}
          ></Button>
        </Box>
      </div>
      {/* </div> */}
    </>
  );
};

export default DeviceCondition;
