import axios from 'axios';
import { statusCodes } from 'api/constant/statusCodes';
import { showErrorHelper } from 'api/helper/error';
import { extractInnermostData } from 'utils/brands';

export const apiGet = async (api) => {
  
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}${api}`);
      
      if (response.status === statusCodes.success) {
        const data = extractInnermostData(response);
        return { data };
      } else {
        showErrorHelper();
        return { data: [] };
      }
    } catch (error) {
      showErrorHelper();
      return { data: [] };
    }
  };

export const apiPost = async(api,body)=>{
try{
  
        const response = await axios.post(`${process.env.REACT_APP_API_URL}${api}`,body ? body : {});
        return response;

    

}catch{
    showErrorHelper();
    return { data: [] };  
}
}