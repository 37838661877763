import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import { useMemo, useState } from 'react';
import '../../../css/phone.css';

const CountryPhoneInputWithFlag = ({
  value,
  onChange,
  errors,
  setFieldValue,
  shouldShowError,
  setShouldShowError,
  className
}) => {
  const displayError = useMemo(() => {
    return errors.phone && shouldShowError >= 2;
  }, [errors, shouldShowError]);
  return (
    <>
      <PhoneInput
        defaultCountry="ae"
        // value={phone}

        onChange={phone => {
          setShouldShowError(prev => prev + 1);
          setFieldValue('phone', phone);
        }}
        placeholder="Phone"
        className={`${displayError ? 'error-Phone' : ''} ${className}`}
      />
      {displayError && (
        <div style={{}} className="phone-error-cont">
          Please enter valid Phone No
        </div>
      )}
    </>
  );
};

export default CountryPhoneInputWithFlag;
