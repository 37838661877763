import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { carouselType } from 'types/carouselType';
import CarBanner1 from '../../assets/CarBanner1.jpg';
import CarBanner2 from '../../assets/CarBanner2.jpg';
import CarBanner3 from '../../assets/CarBanner3.jpg';
import CarBanner4 from '../../assets/CarBanner4.jpg';

// import debounce from 'lodash.debounce';

const Carousel: React.FC<carouselType> = ({
  spaceBetween,
  slidesPerView,
  centeredSlides,
  loop,
  isNavigation,
  // pagination,
  className,
  images,
  autoplay,
  ...restProps
}) => {
  let carImages = [CarBanner1, CarBanner2, CarBanner3, CarBanner4];
  return (
    <>
      <Swiper
        {...restProps}
        spaceBetween={spaceBetween}
        slidesPerView={slidesPerView}
        centeredSlides={centeredSlides}
        loop={loop}
        autoplay={autoplay}
        navigation={isNavigation}
        className={className}
      >
        {carImages.map((images, index) => (
          <SwiperSlide key={index}>
            <img
              src={images}
              alt={`Slide ${index}`}
              className="md:!h-[200px] xl:!h-[230px] !h-[150px] w-[100%] object-fill"
              style={{ borderRadius: '20px' }}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

export default Carousel;
