import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
// import ModelForm from 'components/forms/modelForm';
import ModelForm from 'components/forms/modelForm';
// import modelFor
// import { Grid } from '@mui/material';
// import { Grid } from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  maxWidth: {
    xs: '300px', // For mobile view
    sm: '400px' // For larger screens
  },
  bgcolor: '#f4f5f7',
  boxShadow: 'none',
  outline: 'none',
  border: 'none',
  p: 4,
  borderRadius: '20px'
};
interface IPopup {
  open: boolean;
  setOpen: any;
}
const NotInListing: React.FC<IPopup> = ({ open, setOpen }) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          className="!text-[1.1rem] !font-bold !tracking-wide !mb-[0px] !pb-[0px]"
          component="h2"
        >
          Not In the listing?
        </Typography>
        <Typography
          id="modal-modal-description"
          className="!text-[1rem] !text-[#959798] !tracking-wide  !mt-[5px] !pt-[0px]"
          sx={{ mt: 2 }}
        >
          Don't worry please enter the details
        </Typography>
        <Box>
          <ModelForm />
        </Box>
      </Box>
    </Modal>
  );
};

export default NotInListing;
