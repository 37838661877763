import React from 'react';
import { Link } from 'react-router-dom';
import './index.css'; // The CSS file for animation styles

const NotFound = () => {
  return (
    <div
      className="not-found-container flex  md:!items-center
    !items-center h-[100%]"
    >
      <div className="not-found-content">
        <h1 className="error-code !text-[3rem] md:!text-[4rem]">404</h1>
        <p className="error-message !text-[1rem] md:!text-[3rem]">
          Oops! Page Not Found
        </p>
        <div className="image-container"></div>
        <Link to="/" className="back-home-link">
          Go Back Home
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
