export function unslugify(slug) {
    // Replace hyphens with spaces and capitalize each word
    if(!Array.isArray(slug))
    return slug
        .split('-')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    else{
      let unslugifiedArray = slug.map((slugName)=>{
      return slugName
      .split('-')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
      })
      return unslugifiedArray;
    }
  }