import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

// import React from "react"
const Header = () => {
  const navigate = useNavigate();
  const handleHeaderClick = () => {
    navigate('/');
  };

  return (
    <Box
      className="flex justify-center sm:justify-center md:justify-start cursor-pointer"
      onClick={handleHeaderClick}
    >
      <Box className="md:h-[74px] md:w-[74px] h-[60px] w-[60px] m-4 rounded-full bg-primaryBlue flex justify-center items-center">
        <Typography className="text-white md:!text-[1.4rem] !text-[1.1rem] !font-bold ">
          teler.
        </Typography>
      </Box>
    </Box>
  );
};
export default Header;
