import {
  Grid

  //   TextField
} from '@mui/material';
import React from 'react';
import SearchInput from '../../input/index';
import { ContactFormAutoFillStyle, ContactFormStyles } from 'constants/styles';
import CountryPhoneInputWithFlag from 'components/input/phone';
import AutoComplete from 'components/input/select';

interface IContactForm {
  formik: any;
  setShouldShowError: any;
  shouldShowError: any;
  nationalities: string[];
}
const ContactForm: React.FC<IContactForm> = ({
  formik,
  setShouldShowError,
  shouldShowError,
  nationalities
}) => {
  const [muiPhone, setMuiPhone] = React.useState('+1');
  let { setFieldValue, errors } = formik;

  const handleClick = () => {
    setShouldShowError(2);
  };

  return (
    <>
      <Grid xs={12} className="md:!w[1500px]">
        <SearchInput
          id="name"
          name="name"
          placeholder="Name"
          variant="outlined"
          className="!w-[100%] !border-none !rounded-[16px]"
          sx={ContactFormStyles}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.name}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
        />
      </Grid>
      <Grid xs={12} className="md:!w[1500px]">
        <SearchInput
          id="email"
          name="email"
          placeholder="Email"
          variant="outlined"
          className="!w-[100%] !border-none !rounded-[16px]"
          sx={ContactFormStyles}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
      </Grid>
      <Grid xs={12}>
        <CountryPhoneInputWithFlag
          className={''}
          errors={errors}
          setShouldShowError={setShouldShowError}
          shouldShowError={shouldShowError}
          setFieldValue={setFieldValue}
          value={muiPhone}
          onChange={setMuiPhone}
        />
      </Grid>
      <Grid xs={12}>
        <div className="!border-b-[2px]  !border-[primaryGrey] pb-5">
          <AutoComplete
            sx={ContactFormAutoFillStyle}
            nationalities={nationalities}
            formik={formik}
          />
        </div>
      </Grid>
      <Grid xs={12} className="mt-4">
        <button
          type="submit"
          onClick={handleClick}
          className="bg-primaryBlue !text-white !w-[100%] p-2 md:p-3 rounded-[10px] !text-[1.1rem]"
        >
          Next
        </button>
      </Grid>
    </>
  );
};

export default React.memo(ContactForm);
