import React from 'react';
interface IButtonProps {
  content: any;
  className: string;
  onClick?: () => void;
}
const Button: React.FC<IButtonProps> = ({ content, ...restProps }) => {
  return <button {...restProps}>{content}</button>;
};

export default Button;
