import { Box, Grid, Typography } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { getPopularBrand } from 'api/device/get';
import { formImageUrl } from 'utils/brands';
import { BrandCategoryTypes } from 'types/brandTypes';
import { CardLayoutArrayConst } from '../../../src/constants/index';
import SkelatalLoader from 'layout/loader/card';

interface IPopularBrand {
  categoryId: string;
}
const PopularBrandsLayout: React.FC<IPopularBrand> = ({ categoryId }) => {
  let navigate = useNavigate();
  let [popularBrand, setPopularBrand] = useState(CardLayoutArrayConst);
  let [loading, setLoading] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        let { data } = await getPopularBrand(categoryId);
        setPopularBrand(data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setPopularBrand([]);
        console.error('Error fetching popular brands:', error);
      }
    };

    fetchData();
  }, [categoryId]);
  const location = useLocation();
  const handleBrandClick = (brandName: string, brandId: string) => {
    const currentPath = location.pathname;
    navigate(`${currentPath}/${brandName}`, {
      state: {
        categoryId: categoryId,
        brandId: brandId
      }
    });
  };

  return (
    <Grid md={12} xs={2} order={{ xs: 2, md: 1 }}>
      <Box className="lg:max-w-[500px] xl:max-w-[480px] lg:!pr-4 lg:!pl-2 ml-[20px] ">
        <Typography className="xl:!text-[1.2rem] lg:!text-[1.1rem] !font-semibold !mb-8">
          Popular Brands
        </Typography>
        <Grid container rowGap={2} columnGap={0} className="md:!w-[408px]">
          {!popularBrand.length && (
            <Typography className="!font-[600] !tracking-wide !text-[1.1rem] md:!text-[1.2rem] !px-[0px] md:!text-center">
              No Data Found
            </Typography>
          )}
          {popularBrand?.map((data: BrandCategoryTypes) => {
            return (
              <Grid md={4} sm={6} className="mr-2 lg:mr-0 cursor-pointer">
                {loading ? (
                  <Box>
                    <SkelatalLoader
                      type={'skelatal'}
                      height={100}
                      width={10}
                      variant="rounded"
                      className="mt-[0px] !h-[80px] !w-[120px] !border-r-[2px] !rounded-[20px]"
                    />
                  </Box>
                ) : (
                  <div onClick={_ => handleBrandClick(data.name, data?._id!)}>
                    <Box
                      sx={{ border: '2px solid white' }}
                      className="bg-[#ffffff] flex !justify-center !items-center md:w-[120px] md:h-[70px] w-[90px] h-[60px] px-2 rounded-[18px]"
                    >
                      <img
                        src={formImageUrl(data?.image)}
                        className="md:w-[90px] w-[100%] h-[30px]  lg:h-[40px] xl:h-[50px] object-fill"
                      />
                    </Box>
                  </div>
                )}
                {/* <img
                      src={formImageUrl(data?.image)}
                      className="md:w-[90px] w-[100%] h-[30px]  lg:h-[40px] xl:h-[50px] object-fill"
                    /> */}
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Grid>
  );
};

export default PopularBrandsLayout;
