import { Box, Grid } from '@mui/material';
import { ModelFormStyles } from 'constants/styles';
import SearchInput from '../../input/index';

import CountryPhoneInputWithFlag from 'components/input/phone';
import { useCallback, useState } from 'react';
import '../../../../src/css/phone.css';
import InfoIcon from '@mui/icons-material/Info';
import { useModelFormik } from 'customHooks/formik';
import { postNotInListingData } from 'api/device/get';
import { formEnquiryData } from 'mappers';
import { statusCodes } from 'api/constant/statusCodes';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

interface IModelForm {
  formik?: any;
  handleClick?: () => void;
}

const ModelForm: React.FC<IModelForm> = ({}) => {
  let navigate = useNavigate();
  const [shouldShowError, setShouldShowError] = useState(0);
  const handleListingSubmission = useCallback(async (values: any) => {
    const formedEnquiryData = formEnquiryData(values);
    const response: any = await postNotInListingData(formedEnquiryData);
    if (response?.status! === statusCodes.created) {
      toast.success(
        'Your query has been registred our team will get back to you',
        {
          autoClose: 3000
        }
      );
      setTimeout(() => {
        navigate('/');
      }, 3000);
    }
  }, []);

  const formik = useModelFormik(handleListingSubmission);
  let { setFieldValue, errors } = formik;
  const [muiPhone, setMuiPhone] = useState('+1');

  const handleClick = () => {
    setShouldShowError(2);
    formik.setTouched(
      Object.keys(formik.values).reduce((acc: any, field) => {
        acc[field] = true;
        return acc;
      }, {})
    );
    formik.validateForm();
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Grid
          container
          rowGap={1}
          className="md:!max-h-[240px] max-h-[200px] px-2 !overflow-y-scroll !overflow-x-hidden custom-scrollbar my-4"
        >
          <Grid xs={12} className="md:!w[1500px]">
            <div className="flex justify-between">
              <p className="text-[0.8rem] font-semibold !tracking-wide text-primaryBlue mb-[2px] ml-[3px]">
                Category*
              </p>
              <span title="Category (Example Mobile)">
                <InfoIcon className="text-primaryBlue" />
              </span>
            </div>
            <SearchInput
              id="categoryName"
              name="categoryName"
              placeholder="Enter the Category name here"
              variant="outlined"
              className="!w-[100%] !border-none !rounded-[16px]"
              sx={ModelFormStyles}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.categoryName}
              error={
                formik.touched.categoryName &&
                Boolean(formik.errors.categoryName)
              }
              helperText={
                formik.touched.categoryName && formik.errors.categoryName
              }
            />
          </Grid>
          <Grid xs={12} className="md:!w[1500px]">
            <div className="flex justify-between">
              <p className="text-[0.8rem] font-semibold !tracking-wide text-primaryBlue mb-[2px] ml-[3px]">
                Brand
              </p>
              <span title="Brand (Example Apple)">
                <InfoIcon className="text-primaryBlue" />
              </span>
            </div>
            <SearchInput
              id="brandName"
              name="brandName"
              placeholder="Please Enter Brand name here"
              variant="outlined"
              className="!w-[100%] !border-none !rounded-[16px]"
              sx={ModelFormStyles}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.brandName}
              error={
                formik.touched.brandName && Boolean(formik.errors.brandName)
              }
              helperText={formik.touched.brandName && formik.errors.brandName}
            />
          </Grid>
          <Grid xs={12}>
            <div className="flex justify-between">
              <p className="text-[0.8rem] font-semibold !tracking-wide text-primaryBlue mb-[2px] ml-[3px]">
                Model
              </p>
              <span title="Model (Example IPhone 13)">
                <InfoIcon className="text-primaryBlue" />
              </span>
            </div>
            <SearchInput
              id="modelName"
              name="modelName"
              placeholder="Please Enter Model name here"
              variant="outlined"
              className="!w-[100%] !border-none !rounded-[16px]"
              sx={ModelFormStyles}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.modelName}
              error={
                formik.touched.modelName && Boolean(formik.errors.modelName)
              }
              helperText={formik.touched.modelName && formik.errors.modelName}
            />
          </Grid>
          <Grid xs={12}>
            <div className="flex justify-between">
              <p className="text-[0.8rem] font-semibold !tracking-wide text-primaryBlue mb-[2px] ml-[3px]">
                Additional Info
              </p>
              <span title="Listings (Example RAM, Storage, Specification etc)">
                <InfoIcon className="text-primaryBlue" />
              </span>
            </div>
            <SearchInput
              id="listings"
              multiline
              rows={4} // Initial number of rows (e.g., 4 rows high)
              maxRows={10} // Maximum rows the box will grow to
              name="listings"
              placeholder="Please Enter the device listings"
              variant="outlined"
              className="!w-[100%] !border-none !rounded-[16px] model-Listings"
              sx={ModelFormStyles}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.listings}
              error={formik.touched.listings && Boolean(formik.errors.listings)}
              helperText={formik.touched.listings && formik.errors.listings}
            />
          </Grid>
          <Grid xs={12}>
            <p className="text-[0.8rem] font-semibold !tracking-wide text-primaryBlue mb-[2px] ml-[4px]">
              Name*
            </p>
            <SearchInput
              id="userName"
              name="userName"
              placeholder="Please Enter your Name"
              variant="outlined"
              className="!w-[100%] !border-none !rounded-[16px]"
              sx={ModelFormStyles}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.userName}
              error={formik.touched.userName && Boolean(formik.errors.userName)}
              helperText={formik.touched.userName && formik.errors.userName}
            />
          </Grid>
          <Grid xs={12}>
            <p className="text-[0.8rem] font-semibold !tracking-wide text-primaryBlue mb-[2px] ml-[4px]">
              Email*
            </p>
            <SearchInput
              id="userEmail"
              name="userEmail"
              placeholder="Please Enter your Email"
              variant="outlined"
              className="!w-[100%] !border-none !rounded-[16px]"
              sx={ModelFormStyles}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.userEmail}
              error={
                formik.touched.userEmail && Boolean(formik.errors.userEmail)
              }
              helperText={formik.touched.userEmail && formik.errors.userEmail}
            />
          </Grid>
          <Grid xs={12}>
            <p className="text-[0.8rem] font-semibold !tracking-wide text-primaryBlue mb-[2px] ml-[4px]">
              Phone No*
            </p>
            <CountryPhoneInputWithFlag
              errors={errors}
              setShouldShowError={setShouldShowError}
              shouldShowError={shouldShowError}
              setFieldValue={setFieldValue}
              value={muiPhone}
              onChange={setMuiPhone}
              className="Model-Phone"
            />
          </Grid>
        </Grid>
        <Box className="mt-4">
          <button
            onClick={handleClick}
            type="submit"
            className="bg-primaryBlue !text-white !w-[100%] p-1 md:p-2 rounded-[10px] !text-[1.1rem]"
          >
            Next
          </button>
        </Box>
      </form>
    </>
  );
};

export default ModelForm;
