import { Box, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import getLocation, { FormAddress } from 'utils/geoLocation';

import { Select } from 'antd';

import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import GMaps from 'components/maps';
import { GlobalSelectStyle, StyleSelect } from 'constants/styles';
import NavBar from 'components/navigation';
import TradeInHeader from '../../components/header/trade';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import { formCreateRequestData } from 'mappers';
import { initiateRequestGeneration } from 'api/device/get';

const { Option } = Select;

const GlobalStyles = GlobalSelectStyle;

const StyledSelect = StyleSelect;

export default function Collection() {
  const navigate = useNavigate();

  const location = useLocation();
  const { selectedCondition, freeQuoteId, user } = location.state || {};
  const [geoLocation, setGeoLocation] = useState({
    latitude: 0,
    longitude: 0
  });
  const [selectedValue, setSelectedValue] = useState<string | null>(null);

  const fetchAddress = async ({ lat, long, isAddressType, address }: any) => {
    if (lat && long)
      setGeoLocation({ latitude: Number(lat), longitude: Number(long) });
    const { locationFromLatLong, locationFromAddress } = await getLocation();
    let result;
    if (!isAddressType) {
      result = await locationFromLatLong(lat, long);
      let [selectedCurrentLocation] = FormAddress([result]);
      setSelectedValue(selectedCurrentLocation.label);
    } else {
      result = await locationFromAddress(address);
    }
    return result;
  };

  const handleLocationFetch = async (
    isAddressType: Boolean,
    address?: string
  ) => {
    setOptions([]);

    try {
      if (!isAddressType)
        navigator.geolocation.getCurrentPosition(
          async pos => {
            const { latitude, longitude } = pos.coords;
            let result: any = await fetchAddress({
              lat: latitude,
              long: longitude,
              isAddressType: isAddressType
            });
            let [addressOptionResult]: any = FormAddress([result]);

            setOptions([addressOptionResult]);
          },
          error => {
            console.error('Error getting location:', error);
          }
        );
      else {
        let result = await fetchAddress({
          isAddressType: isAddressType,
          address: address
        });
        let addressOptionResult = FormAddress(result);
        setOptions(addressOptionResult);
      }
    } catch (error) {
      setOptions([]);
      setGeoLocation({ latitude: 0, longitude: 0 });
      console.error(error, 'Error while getting address');
    }
  };
  let [options, setOptions] = useState<any>([]);

  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    if (inputValue) {
      handleLocationFetch(true, inputValue);
    }
  }, [inputValue]);

  const handleOptionSelect = (event: any) => {
    let { geoLocation, addressVal } = JSON.parse(event);
    setSelectedValue(addressVal);
    let parsedLocation = geoLocation;
    if (parsedLocation?.location) {
      let { location } = parsedLocation;
      setGeoLocation({
        latitude: Number(location?.lat) || 0,
        longitude: Number(location?.lng) || 0
      });
    }
  };

  const handleSubmission = async () => {
    if (geoLocation.latitude && geoLocation.longitude) {
      try {
        const formattedData = formCreateRequestData({
          selectedValue,
          selectedCondition,
          user,
          geoLocation
        });
        const { data } = await initiateRequestGeneration(
          formattedData,
          freeQuoteId
        );
        if (data?.device) {
          navigate('/create-request', {
            state: {
              createdDeal: data,
              lattitude: geoLocation.latitude,
              longitude: geoLocation.longitude
            }
          });
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      toast.error('Please select location', {
        autoClose: 3000
      });
    }
  };

  return (
    <>
      <TradeInHeader />

      <NavBar></NavBar>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%'
        }}
        className="flex justify-center !mt-10"
      >
        <Grid
          container
          rowGap={2}
          style={{
            display: 'flex',
            justifyContent: 'center'
          }}
          className="flex md:!justify-center max-w-[300px] md:min-w-[500px] border-t-[2px] py-4  border-[primaryGrey]"
        >
          <Grid item xs={12}>
            <Typography className="!font-bold md:!text-[1.3rem] py-4">
              Collection
            </Typography>
            <Box className="mb-4">
              <Box display="flex">
                <GlobalStyles />
                <StyledSelect
                  // open={true}
                  onChange={e => handleOptionSelect(e)}
                  value={selectedValue} // Controlled value
                  showSearch
                  placeholder={
                    <span className="text-[#ccc8c8] !text-[1rem] pl-3">
                      Location
                    </span>
                  }
                  onSearch={e => setInputValue(e)}
                  filterOption={false} // Disable built-in filtering
                  style={{ width: '100%' }}
                  dropdownRender={menu => <>{menu}</>}
                >
                  {options.map((option: any) => (
                    <Option
                      key={option.value}
                      // onClick={() => handleOptionSelect(option.geometry)}
                      value={JSON.stringify({
                        geoLocation: option.geoLocation,
                        addressVal: option.label
                      })}
                    >
                      {option.label}
                    </Option>
                  ))}
                </StyledSelect>
                <span
                  onClick={e => {
                    e.stopPropagation(); // Prevent triggering select dropdown on click
                    handleLocationFetch(false);
                  }}
                  className="cursor-pointer flex items-center relative right-[36px]"
                >
                  <LocationSearchingIcon
                    className="!text-primaryBlue !cursor-pointer"
                    style={{ fontSize: 19 }}
                  />
                </span>
              </Box>
              <GMaps
                Lattitude={geoLocation.latitude}
                Longitude={geoLocation.longitude}
                height={'38vh'}
                className=" my-8 w-full rounded-[20px] overflow-hidden border-2 border-[#dedfe1]"
              />
            </Box>
            <Box>
              <button
                type="submit"
                onClick={handleSubmission}
                className="bg-primaryBlue !text-white !w-[100%] p-2 rounded-[10px] !text-[0.9rem] md:!text-[1.1rem]"
              >
                Confirm
              </button>
            </Box>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
