import { useFormik } from 'formik';
import { isPhoneValid } from 'utils/validation';
import * as Yup from 'yup';

const maxWords = 2;
export const useModelFormik = (
  handleListingSubmission: (values: any) => void
) => {
  return useFormik({
    initialValues: {
      categoryName: '',
      brandName: '',
      modelName: '',
      phone: '',
      userEmail: '',
      userName: '',
      listings: ''
    },
    validationSchema: Yup.object({
      categoryName: Yup.string().required('Please enter category name'),
      modelName: Yup.string(),
      brandName: Yup.string(),
      userName: Yup.string().required('Please enter name'),
      listings: Yup.string().test(
        'max-words',
        `Maximum ${maxWords} words allowed`,
        (value = '') => {
          const words = value.trim().split(/\s+/);
          return words.length <= maxWords;
        }
      ),
      phone: Yup.string()
        .required('Phone number is required')
        .test('is-valid-phone', 'Phone is not valid', value => {
          return isPhoneValid(value);
        }),
      userEmail: Yup.string()
        .email('Enter a valid Email')
        .required('Please enter your email')
    }),

    onSubmit: values => {
      handleListingSubmission(values);
    }
  });
};
