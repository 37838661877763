import axios from 'axios';
import Apitypes from 'api/constant';
import { showErrorHelper } from 'api/helper/error';
import { brandApiHelper } from 'utils/brands';
import { apiGet,apiPost } from 'api';
import { statusCodes } from 'api/constant/statusCodes';
import {BARND_TYPE} from "customHooks/index";


export const DeviceCategory = async type => {
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_API_URL}${type}`
    );
    if (response.status === statusCodes.success) {
      let { data } = response;
      return data;
    } else {
      showErrorHelper();
      return { data: [] };
    }
  } catch {
    showErrorHelper();
    return { data: [] };
  }
};

export const getBrandPageDetails = async (brandData) => {
  const { api, type } = brandApiHelper(brandData);
  if(api){
  const {body} = brandData;
  let response;
  if(type !== BARND_TYPE.attributesData)
     response = await apiGet(api);
  else{
    response = await apiPost(api,body);
    
  }

  return { ...response, type };
}else{
  return {data:[],type:''}
}
};

export const generateFreeQuote = async(deviceId)=>{
  const {quote: freeQuote} = Apitypes;
  let response = await apiGet(`${freeQuote}${deviceId}`);

  return response;
}

export const getPopularBrand = async(categoryId)=>{
  const {brandByCategory} = Apitypes;
  let response = await apiGet(`${brandByCategory}${categoryId}`);

  return response;
}

export const initiateRequestGeneration=async(body,freeQuoteId)=>{
  let api = `${Apitypes.requestGeneration}${freeQuoteId}`;
  const response = await apiPost(api,body);
  return response;

}

export const postNotInListingData = async(body)=>{
  let api = `${Apitypes.enquiry}`;
  const response = await apiPost(api,body);
  return response;
}

