import { Box } from '@mui/material';
import { selectedNavPage } from 'utils/devices';

let UnderLine = ({ selectType, orgType, isHomePage }: any) => {
  return (
    <Box
      className={`transition-all duration-300 ease-in-out bg-primaryOrange h-[3px] rounded-[10px] ${
        selectedNavPage(selectType, orgType) || isHomePage
          ? 'w-[30px]'
          : 'w-[0px]'
      }`}
    />
  );
};
export default UnderLine;
