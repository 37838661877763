import { Box, Grid, Typography } from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';
import SearchInput from '../../components/input';
import { groupBrandsByLetter } from 'utils/brands';
import { allBrandInputStyle } from 'constants/styles';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import { BrandCategoryTypes } from 'types/brandTypes';
import { useNavigate, useLocation } from 'react-router-dom';
import SkelatalLoader from 'layout/loader/card';
import { CardLayoutArrayConst } from '../../../src/constants/index';
import NotInListing from 'components/popup/listing';

let sx = allBrandInputStyle;

interface IBrandCatProps {
  brandData: BrandCategoryTypes[];
  categoryId: string;
  loading: Boolean;
}

const AllBrandLayout: React.FC<IBrandCatProps> = ({
  brandData,
  categoryId,
  loading
}) => {
  let [searchVal, setSearchVal] = useState('');
  let [open, setOpen] = useState(false);
  let navigate = useNavigate();
  const location = useLocation();
  let AllBrands: any = useMemo(() => {
    return groupBrandsByLetter(searchVal, brandData);
  }, [searchVal, brandData]);

  const handleSearchChange = useCallback(
    (e: any) => {
      setSearchVal(e.target.value);
    },
    [searchVal, setSearchVal, AllBrands]
  );

  const handleBrandClick = (brandName: string, brandId: string) => {
    const currentPath = location.pathname;
    navigate(`${currentPath}/${brandName}`, {
      state: {
        categoryId: categoryId,
        brandId: brandId
      }
    });
  };

  const CheckNotInListing = () => {
    setOpen(true);
  };

  return (
    <Grid
      xs={12}
      order={{ xs: 1, md: 2 }}
      md={10}
      className="!border-l-[1px solid grey] lg:w-[auto] w-[100%] "
    >
      <NotInListing open={open} setOpen={setOpen} />
      <Box
        className="!border-l  !border-grey-400 !px-4 md:!px-8"
        style={{ borderColor: '#d6d7db' }}
      >
        <Box className="flex justify-between mb-4">
          <Typography className="md:!text-[1.1rem] !text-[1rem] !font-[600]">
            All Brands
          </Typography>
          <Typography
            onClick={() => CheckNotInListing()}
            className="md:!text-[1rem] !text-[0.9rem] !font-[600] !text-primaryOrange cursor-pointer"
          >
            Not in the listing?
          </Typography>
        </Box>
        <Box className="mb-4">
          <SearchInput
            onChange={(e: any) => handleSearchChange(e)}
            className="xl:!min-w-[400px] md:!min-w-[399px] !max-h-[50px] md:!max-h-[54px] min-w-[100%] !w-[100%] !rounded-10px bg-white"
            sx={sx}
            placeholder="Search"
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end">
                    <SearchIcon style={{ fontSize: 22 }} />
                  </IconButton>
                </InputAdornment>
              )
            }}
          ></SearchInput>
        </Box>
        <Box className="max-h-[350px] overflow-y-scroll !scrollbar-thin !scrollbar-hide brand-container">
          {!loading ? (
            Object.keys(AllBrands).map(letter => (
              <div key={letter} style={{ marginBottom: '20px' }}>
                <h3 className="!text-primaryGrey md:!text-[1rem]">{letter}</h3>
                {AllBrands[letter]?.length > 0 ? (
                  <ul>
                    {AllBrands[letter].map((brand: any, index: number) => (
                      <li
                        onClick={() => handleBrandClick(brand.name, brand.id)}
                        className="md:!text-[1rem] cursor-pointer"
                        key={index}
                      >
                        {brand.name}
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p>No brands under {letter}</p>
                )}
              </div>
            ))
          ) : (
            <>
              <ul>
                {CardLayoutArrayConst.map((_: any) => {
                  return (
                    <SkelatalLoader
                      type={'skelatal'}
                      height={100}
                      width={10}
                      variant="rounded"
                      className="mt-[10px] !h-[16px] !w-[150px] !border-r-[2px] !rounded-[20px]"
                    />
                  );
                })}
                <li></li>
              </ul>
            </>
          )}
        </Box>
      </Box>
    </Grid>
  );
};

export default React.memo(AllBrandLayout);
