export  const defaultNavigationPage = "Device";

export const CardLayoutArrayConst = [{
    name: '',
    icon: '',
    status: '',
    id: '',
    _id: '',
    image: ''
},
{
    name: '',
    icon: '',
    status: '',
    id: '',
    _id: '',
    image: ''
},
{
    name: '',
    icon: '',
    status: '',
    id: '',
    _id: '',
    image: ''
},
{
    name: '',
    icon: '',
    status: '',
    id: '',
    _id: '',
    image: ''
}]