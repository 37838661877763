import React from 'react';
import TextField, { TextFieldVariants } from '@mui/material/TextField';

interface SearchInputTypes {
  className?: string;
  sx?: any;
  onChange: (e: any) => void;
  onBlur?: (e: any) => void;
  value?: any;
  error?: boolean;
  helperText?: any;
  InputProps?: any;
  placeholder?: string;
  variant?: TextFieldVariants;
  id?: string;
  name?: string;
  label?: string;
  multiline?: any;
  rows?: number;
  maxRows?: number;
}

const SearchInput: React.FC<SearchInputTypes> = ({
  className,
  id,
  ...restProps
}) => {
  return <TextField id={id || ''} className={className} {...restProps} />;
};
export default React.memo(SearchInput);
