export const selectedNavPage =(selectedtype, navType)=>{
    let isSelected = false;
    switch(selectedtype){
        case navType:
            isSelected=true;
            break;
        default:
            isSelected= false
            break;  
    }
    return isSelected;
}