import { Box, Grid } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import DoneIcon from '@mui/icons-material/Done';
import { Typography } from 'antd';
import GMaps from 'components/maps';
import { hexaGonStrucutreStyle } from 'constants/styles';
import ReplyIcon from '@mui/icons-material/Reply';
import { useLocation, useNavigate } from 'react-router-dom';
import { formImageUrl } from 'utils/brands';

const RequestCreation = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { createdDeal, lattitude, longitude } = location.state || {};
  useEffect(() => {
    if (!createdDeal) navigateBackToHome(false);
  }, [createdDeal]);
  const navigateBackToHome = (shouldDeleteHistory: Boolean) => {
    if (shouldDeleteHistory) {
      navigate('/', { replace: true });
      const handlePopState = () => {
        navigate('/', { replace: true });
      };

      window.addEventListener('popstate', handlePopState);

      return () => {
        window.removeEventListener('popstate', handlePopState);
      };
    } else {
      // navigate('/device');
    }
    return;
  };

  const dealAttribute = useMemo(() => {
    return createdDeal?.device?.attributes
      ? Object?.values(createdDeal?.device?.attributes)
      : [];
  }, [createdDeal]);

  return (
    <>
      <div className="mx-auto w-[100%] xl:w-1/2 !text-center">
        {createdDeal.device && (
          <>
            <Box sx={hexaGonStrucutreStyle}>
              <DoneIcon
                sx={{ color: 'white', fontSize: '30px', fontWeight: '700' }}
              />{' '}
              {/* Centered icon */}
            </Box>
            <Box>
              <Typography className="!font-semibold !text-[1.1rem] md:!text-[1.3rem]">
                Amazing!
              </Typography>
            </Box>
            <Box>
              <Typography className="!font-[400] !text-primaryGrey !text-[0.9rem] md!text-[1rem]">
                You have successfully created a request.
              </Typography>
            </Box>
            <Box>
              <Typography className="!font-[400] !text-primaryGrey !text-[0.9rem] md!text-[1rem]">
                Our team will get back to you soon
              </Typography>
            </Box>
            <Box>
              <Typography className="!font-bold md:!text-[1.2rem] !text-[1.1rem]">
                Deal ID: {createdDeal?.dealNumber}
              </Typography>
            </Box>
          </>
        )}
        <Grid
          container
          columnGap={2}
          rowGap={2}
          className="!flex justify-center !text-left px-4 mt-6 lg:px-20 xl:!px-0"
        >
          <Grid md={7} xs={12}>
            <Box className="px-6 pb-2 pt-4 border-2 rounded-[20px]   border-white bg-[#f4f5f7]">
              <Typography className="!text-[#9da4b8] md:!text-[1rem] !text-[0.8rem] ">
                SUMMARY
              </Typography>
              <Box>
                <Typography className="!text-primaryBlue xl:!text-[1.4rem] md:!text-[1.3rem] text-[1.2rem] !font-bold">
                  {createdDeal?.price}
                </Typography>
              </Box>
              <Box>
                <Typography className="!text-[black] lg:!text-[1.1rem] !text-[1.rem]  !font-[400]">
                  {createdDeal?.customer}
                </Typography>
              </Box>
              <Box className="flex mt-4 py-4 border-t-2 border-t-[primaryBlue]">
                <img
                  alt={'Image'}
                  className="md:!w-[140px] md:!h-[120px] !w-[100px] !h-[150px] object-cover !rounded-[10px]"
                  src={formImageUrl(createdDeal?.device?.image)}
                ></img>
                <Box className="ml-4">
                  <Typography className="md:!text-[1.2rem] !text-[1rem] !font-semibold">
                    {createdDeal?.device?.name}
                  </Typography>
                  <Grid container columnGap={1} rowGap={2}>
                    {dealAttribute?.map((value: any) => {
                      return (
                        <Grid className="!text-[0.8rem] xl:!text-[0.9rem]  mt-2 p-2 bg-[#e7e7e7] rounded-[50px] text-center">
                          {value}
                        </Grid>
                      );
                    })}
                  </Grid>
                </Box>
              </Box>
            </Box>
            <Box
              className={'hidden lg:block cursor-pointer'}
              onClick={() => navigateBackToHome(true)}
            >
              <Typography className="!text-[1rem] mt-2 !font-semibold !text-primaryBlue">
                Back To Home
              </Typography>
            </Box>
          </Grid>

          <Grid md={4} xs={12} className=" ">
            <Box className="md:!h-[91%] !p-[0px] border-2 rounded-[20px] border-white">
              <GMaps
                Lattitude={lattitude || 0}
                Longitude={longitude || 0}
                height={'100%'}
                className=" w-full lg:h-[100%] h-[32vh]  rounded-[20px] overflow-hidden"
              ></GMaps>
              <Box className={'!w-[100%] hidden lg:block '}>
                <Typography className="!text-[1rem] xl:!ml-[10px] mt-2 !font-semibold !text-right !text-primaryBlue">
                  <ReplyIcon className="scale-x-[-1] mb-[4px]" /> Share
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </div>
    </>
  );
};
export default React.memo(RequestCreation);
